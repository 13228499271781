/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(window).load(function(){
           $('body').addClass('fadeIn');
           $('body').css('opacity', '1.0');
           // $('body').css('opacity', 1);
           // $('.logo img').css('margin-left', 0);
           // $('.logo img').css('opacity', 1);    
           //setTimeout(moveIn, 800);
        });

        if(window.innerWidth < 767)
        {
        	$(".cat-wrap").click(function(e){
        		
        		if($(this).hasClass("almost"))
        		{
        			return true;
        		}

        		e.preventDefault();
        		$(".cat-wrap").removeClass("almost");
        		$(this).addClass("almost");
        	});
        }

         $('.bxslider').bxSlider({
          mode:'fade',
          adaptiveHeight: true
         });

         	navSlider = $('.nav-slider').bxSlider({
	           	minSlides: 7,
	           	maxSlides: 7,
	           	slideWidth: 1000,
	           	moveSlides: 1,
         	});

        
		    $('.carousel').carousel({
		        interval: false
		    }).on('slide.bs.carousel', function (e)
		    {
		        var nextH = $(e.relatedTarget).height();
		        $(this).find('.active.item').parent().animate({ height: nextH }, 500);
		    });
		

         
         /*if(typeof slideToScroll != 'undefined')
         {
         	//slideCount = $('.activeslide').data('number');
         	console.log(slideToScroll);
         	window.setTimeout(function(){
         		navSlider.goToSlide(slideToScroll);
         	}, 1000);
         	
         }*/

        $('.navbar-toggle').click(function(e){
          e.preventDefault();
          $("#nav-primary").addClass('in');
          $("#nav-primary").show();

          return false;
        });

        $('.nav-close, #nav-primary in a').click(function(){
           $("#nav-primary").removeClass('in');
           $("#nav-primary").hide();
        });

        $('#gform_submit_button_1').val('Submit');

        $('.gf-add-placeholder').each(function(){
          placeHolder = $(this).find('.gfield_label').text();
          placeHolder = placeHolder.replace('*', ' ');
          // placeHolder = placeHolder.replace('Hello', 'Hello... ');
          console.log(placeHolder);
          $(this).find('.ginput_container').children().attr('placeholder', placeHolder);

        });

        $(window).scroll(function() {
            var windscroll = $(window).scrollTop();
            if($(window).width() < 781) {
              return false;
            }

            if (windscroll >= 350) {
              $('.banner').addClass('sm');
              $('body').css('padding-top', '145px');
              //$('.banner.sm').css('top', 0);
            } else {
              //$('.banner').css('top', '-90px');

            }

            if (windscroll < 61) { 
              $('.banner').removeClass('sm');
              $('body').css('padding-top', 0);
            }


        });

        function moveIn(){ 
        }

        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
       $(window).load(function(){
           bHeight = $(window).height();
           bHeight = bHeight - 400;
           $('.bx-wrapper, .bx-viewport, .bxslider li').height(bHeight);     
           $('#sub-blocks').css('margin-top', '-90px');
       });

       $(window).resize(function(){
           bHeight = $(window).height();
           bHeight = bHeight - 400;
           $('.bx-wrapper, .bx-viewport, .bxslider li').height(bHeight);  
         });
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'company_profile': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('#menu-item-98').click(function(e){
          e.preventDefault();
              $('html, body').animate({
                  scrollTop: $("#contact").offset().top - 100
              }, 2000);
        });
      }
    },
    'archive': {
      init:function(){

        //slideCount = $('.activeslide').data('number');
        // for some reason, this was cusing an error
        //navSlider.goToSlide(slideCount);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
